import React from 'react'
import { graphql } from 'gatsby'
import PageBuilder from '../components/pageBuilder'
import SEO from '../components/seo'
import Helmet from 'react-helmet'

export const query = graphql`
  query KarrierePageQuery {
    karrierePage: sanityPage(slug: { current: { eq: "karriere" } }) {
      id
      title
      ...PageBuilder
    }
  }
`

const KarrierePage = props => {
  const { data, errors } = props
  const pageData = data && data.karrierePage
  return (
    <>
      {pageData && pageData.title && <SEO title={pageData.title} />}
      <Helmet
        bodyAttributes={{
          class: 'is-page-karriere',
        }}
      />
      <PageBuilder pageBuilder={pageData.pageBuilder} _rawPageBuilder={pageData._rawPageBuilder} />
    </>
  )
}

export default KarrierePage
